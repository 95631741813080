import React from "react"
import SVG from "./svg"
import ResponsiveSpacing from "./responsive-spacing"
import RoughEdge from "./rough-edge"
import styled from "styled-components"

const BannerStyled = styled.div`
  background-color: ${(p) => p.color};
  position: relative;
  z-index: 1;

  .inner {
    min-height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  .svg {
    display: flex;
    align-self: center;
    text-align: center;
    @media (max-width: 768px) {
      width: 90%;
      margin: 0 auto;
    }
  }

  .text {
    font-family: "interstate-compressed";
    font-size: 2.4em;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: ${(p) => p.theme.White};
    margin: 23px auto;
    max-width: 450px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
`

const Banner = ({ inner, color }) => {
  return (
    <BannerStyled color={color}>
      <RoughEdge className="top" color={color} />
      <ResponsiveSpacing>
        <div className="inner">
          <SVG name="LWave" className="svg" color={"White"} />
          <div className="text" dangerouslySetInnerHTML={{ __html: inner }} />
          <SVG name="LWave" className="svg" color={"White"} />
        </div>
      </ResponsiveSpacing>
      <RoughEdge className="bottom" color={color} />
    </BannerStyled>
  )
}

export default Banner

import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import Layout from "../layout"
import ResponsiveSpacing from "../responsive-spacing"
import SEO from "../seo"
import Heading from "../heading"
import BottomColorNav from "../bottom-color-nav"
import Inner from "../inner"
import Img from "gatsby-image/withIEPolyfill"
import Button from "../button"
import Garnish from "../garnish"
import Banner from "../banner"
import Merch from "./merch-item"
import BreadCrumbs from "../breadcrumbs"
import PromoSticker from "../promo-sticker"
import SVG from "../svg"
import { connect } from "react-redux"

const MoreMerchStyled = styled.div`
  max-width: 1100px;
  margin: 100px auto;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin: 50px 8px;
  }
`

const MoreMerch = ({ merch, dispatch }) => {
  return (
    <MoreMerchStyled>
      {merch.map((m, i) => {
        return <Merch {...m} dispatch={dispatch} key={i} index={i} />
      })}
    </MoreMerchStyled>
  )
}

const ContentStyled = styled.div`
  margin: 143px auto 0 auto;

  @media (max-width: 768px) {
    margin: 0;
  }

  h1 {
    font-size: 9.2em;
    font-family: "interstate-compressed";
    margin: 0 0 20px 0;
    line-height: 0.9em;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    color: ${(p) => p.theme.DarkBlue};

    @media (max-width: 768px) {
      font-size: 6em;
    }
  }

  .topLine {
    font-size: 2.4em;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${(p) => p.theme.Blue};

    @media (max-width: 768px) {
      font-size: 1.6em;
      margin: 20px 0 5px 0;
    }
  }

  .info {
    .price {
      font-weight: bold;
      margin: 20px 0;
      font-size: 2.4em;
      color: ${(p) => p.theme.DarkBlue};
    }
  }

  h4 {
    font-family: "interstate-compressed";
    margin: 10px 0 0 0;
    font-size: 2.4em;
    color: ${(p) => p.theme.Blue};
    span {
      color: ${(p) => p.theme.DarkBlue};
    }
  }

  .errorMessage {
    font-size: 1.4em;
    color: red;
    min-height: 18px;
  }

  .main {
    position: relative;

    .control {
      position: absolute;
      top: 45%;
      z-index: 2;
      cursor: pointer;
      svg {
        transform: scale(2);
      }
    }

    .prev {
      left: 10px;
      transform: rotate(90deg);
    }

    .next {
      right: 10px;
      transform: rotate(-90deg);
    }
  }

  .mainImage {
    width: 100%;
    max-width: 450px;
    max-height: 450px;
  }

  .variants {
    border-top: 1px solid ${(p) => p.theme.Grey};
    padding: 0;
    position: relative;
  }

  .selectboxes {
    position: relative;
  }

  .down {
    user-select: none;
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 35px;
  }

  .garnishCont {
    position: relative;
  }

  .details-top {
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    color: ${(p) => p.theme.Blue};
  }

  .checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 90px;
    margin: 10px 0;
  }

  .select {
    border: none;
    -webkit-appearance: none;
    outline: none;
    width: 100%;
    color: ${(p) => p.theme.DarkBlue};
    padding: 5px 0;
    margin: 10px 0;
    font-family: "interstate-compressed";
    font-size: 2em;
    border-bottom: 3px solid ${(p) => p.theme.DarkBlue};
    border-radius: 0;
    background: ${(p) => p.theme.White};

    &:after {
    }
  }

  .merchDetail {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 960px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 100px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .col1,
    .col2 {
      width: 100%;
      max-width: 450px;
      margin-right: 30px;
      @media (max-width: 768px) {
        margin-right: 0px;
        padding: 0 5px;
      }
    }
  }

  .select option {
    text-transform: uppercase;
    color: ${(p) => p.theme.DarkBlue};
  }

  .actions {
    margin-top: 20px;
  }

  .priceCols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
`

const CheckBoxStyled = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid ${(p) => p.theme.Grey};
  border-color: ${(p) => (p.checked ? p.theme.DarkBlue : p.theme.Grey)};

  &:hover,
  &.selected {
    border-color: ${(p) => p.theme.Blue};
  }

  > .inner {
    border-radius: 100%;
    width: 34px;
    height: 34px;
    background: ${(p) => p.color};
  }
`

const CheckBox = ({ name, color, value, setVariant, variant }) => {
  return (
    <CheckBoxStyled
      checked={value === variant[name]}
      color={color}
      className={value === variant[name] ? "selected" : ""}
    >
      <div
        className="inner"
        onClick={(e) =>
          setVariant({
            ...variant,
            [name]: value,
          })
        }
      />
    </CheckBoxStyled>
  )
}

// option_choices {
//   label
//   choices
//   default_choice
//   input_type
// }
// option_skus {
//   labels
//   sku
//   price
//   sold_out
// }

const Variants = ({ fields, theme, setVariant, variant, errors }) => {
  const merchColors = {
    BLUE: theme.DarkBlue,
    WHITE: theme.White,
  }
  console.log("VARIANT", variant)

  const { option_choices, option_skus } = fields
  // If they're sold out they're thrown out.
  const availableSkus = option_skus
    ? option_skus.filter((x) => !x.sold_out)
    : []

  let availableLabels = []
  availableSkus.map((as) => {
    as.labels
      .split(",")
      .map((x) => x.trim())
      .forEach((label) => {
        availableLabels.push(label)
      })
  })
  let all = {}
  availableLabels.map((lab) => {
    all[lab] = true
  })
  // Hopefull a clever way of getting all the labels only once.
  availableLabels = Object.keys(all)

  // Non variant merch has to eat too.
  if (!option_choices || option_choices.length === 0) return ""

  const variantOptions = option_choices.map((option_choice, index) => {
    let { choices, default_choice, label, input_type } = option_choice
    const onlyChoices = choices
      .split(",")
      .map((c) => c.trim())
      .filter((ch) => availableLabels.includes(ch))
    if (!onlyChoices || onlyChoices.length === 0) return ""
    const oneChoice = onlyChoices.length === 1

    return (
      <div key={index}>
        <h4>{oneChoice ? label : "Select " + label}</h4>
        {input_type === "Buttons" ? (
          <div className="checkboxes">
            {onlyChoices.map((ch, i) => {
              return (
                <CheckBox
                  name={label}
                  key={i}
                  value={ch}
                  setVariant={setVariant}
                  color={merchColors[ch]}
                  variant={variant}
                />
              )
            })}
          </div>
        ) : (
          <div className="selectboxes">
            <div className="down">
              <SVG name="DownArrow" color={theme.DarkBlue} />
            </div>
            <select
              name={label}
              onChange={(e) => {
                const value = e.target.value
                setVariant({ ...variant, [label]: value })
              }}
              className="select"
              value={variant[label]}
            >
              {onlyChoices
                ? onlyChoices.map((ch, i) => {
                    return <option key={i}>{ch}</option>
                  })
                : ""}
            </select>
            <div className="errorMessage">{errors}</div>
          </div>
        )}
      </div>
    )
  })
  return <div className="variants">{variantOptions}</div>
}

const PlaylistStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  max-width: 450px;
  width: 100%;

  .smallImage {
    width: 60px;
    margin: 0 5px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 50px;
    }
    &.active {
      opacity: 1;
    }

    @media (min-width: 768px) {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
`

const Playlist = ({ fields, setCurrentImage, currentImage }) => {
  if (fields.images.length <= 1) return ""
  return (
    <PlaylistStyled>
      {fields.images.map((img, index) => {
        return (
          <div
            key={index}
            className="playlistImage"
            onMouseOver={(e) => setCurrentImage(index)}
            onClick={(e) => setCurrentImage(index)}
          >
            <Img
              className={`smallImage ${index === currentImage ? "active" : ""}`}
              fluid={img.image.localFile.childImageSharp.fluid}
            />
          </div>
        )
      })}
    </PlaylistStyled>
  )
}

const Content = (props) => {
  const theme = useContext(ThemeContext)
  const [variantChoices, setVariantChoices] = useState({})
  const [errors, setErrors] = useState("")
  const [currentImage, setCurrentImage] = useState(0)

  let { merch, dispatch } = props
  merch = merch.filter((m) => {
    return !m.acf.not_for_sale
  })
  const fields = props.product.acf
  const { name, price, sku, images, option_skus, option_choices } = fields
  const unique = (v, i, s) => s.indexOf(v) === i
  const availableSkus =
    option_skus && option_skus.length > 0
      ? option_skus.filter((x) => !x.sold_out)
      : []

  const skuInfoFromChoices = (vc) => {
    const choiceValuesArray = Object.values(vc)
    if (choiceValuesArray.length === 0) return {}

    const found = availableSkus
      .map((info) => {
        const { labels, sku, price } = info
        const labelsArray = labels.split(",").map((l) => l.trim())
        const foundHere = choiceValuesArray.filter((value) =>
          labelsArray.includes(value)
        )
        if (
          foundHere.length != 0 &&
          foundHere.length === choiceValuesArray.length
        ) {
          return info
        } else {
          return null
        }
      })
      .filter((x) => !!x)
    return found[0] || {}
  }

  const lineitemsFromChoices = (vc) => {
    const keys = Object.keys(vc)
    if (keys && keys.length === 0) return []
    return keys.map((ok) => {
      return ok + ` : ` + vc[ok]
    })
  }

  const info = skuInfoFromChoices(variantChoices)
  // if info is empty, use the non-variant price and sku.
  const product = {
    name: name,
    image: images[currentImage].image.localFile.childImageSharp.fluid.src,
    price: info.price || price,
    sku: info.sku || sku,
    lineItems: lineitemsFromChoices(variantChoices),
  }

  // Load Defaults if empty
  if (
    option_choices &&
    option_choices.length > 0 &&
    Object.keys(variantChoices).length === 0
  ) {
    let result = variantChoices
    option_choices.forEach((oc) => {
      return (result[oc.label] = oc.default_choice)
    })
    setVariantChoices(result)
  }

  const g = fields.garnish && fields.garnish.length >= 1 ? fields.garnish : []
  const trail = [
    {
      label: "MERCH",
      link: "/merch/",
    },
    {
      label: name,
      link: "",
    },
  ]

  const promotion = fields.promotion

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <ContentStyled fields={fields}>
      <SEO
        title={search_title || fields.name}
        description={search_description}
        headline={share_headline || fields.name}
        share_comment={share_comment}
        image={share_image || fields.images[0]}
      />
      <ResponsiveSpacing>
        <Inner maxWidth="960px">
          <BreadCrumbs trail={trail} />
        </Inner>
        <div className="merchDetail">
          <div className="col1">
            <div className="main">
              <PromoSticker promotion={promotion} className="topLeftInside" />{" "}
              {fields.images.length > 1 ? (
                <div
                  className="prev control"
                  onClick={(e) => {
                    const i =
                      currentImage > 0
                        ? currentImage - 1
                        : fields.images.length - 1
                    setCurrentImage(i)
                  }}
                >
                  <SVG name="DownArrow" color={theme.White} />
                </div>
              ) : (
                ""
              )}
              <Img
                className="mainImage"
                fluid={
                  fields.images[currentImage].image.localFile.childImageSharp
                    .fluid
                }
              />
              {fields.images.length > 1 ? (
                <div
                  className="next control"
                  onClick={(e) => {
                    const i =
                      currentImage < fields.images.length - 1
                        ? currentImage + 1
                        : 0
                    setCurrentImage(i)
                  }}
                >
                  <SVG name="DownArrow" color={theme.White} />
                </div>
              ) : (
                ""
              )}
            </div>
            <Playlist
              fields={fields}
              setCurrentImage={setCurrentImage}
              currentImage={currentImage}
            />
          </div>
          <div className="col2">
            <div
              className="topLine"
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: fields.top_line,
              }}
            />
            <div className="info">
              <h1
                tabIndex="0"
                dangerouslySetInnerHTML={{
                  __html: fields.headline,
                }}
              />
              <SVG name="SWave" className="swave" color={theme.Blue} />
              <div className="priceCols">
                <div className="price">${product.price}</div>
              </div>
              <p className="description">{fields.short_info}</p>
              {promotion === "sold-out" ? (
                <Button icon="+" disabled={true}>
                  ADD TO CART
                </Button>
              ) : (
                <div>
                  <Variants
                    fields={fields}
                    theme={theme}
                    setVariant={setVariantChoices}
                    variant={variantChoices}
                    errors={errors}
                  />
                  <div className="actions">
                    {promotion === "sold-out" ? (
                      <Button icon="+" disabled={true}>
                        ADD TO CART
                      </Button>
                    ) : (
                      <Button
                        className="cart-button"
                        icon="+"
                        onClick={(e) => {
                          // const errorsFound = validator(product, fields)
                          // if (!errorsFound) {
                          dispatch({ type: "ADD_TO_CART", product: product })
                          // } else {
                          //   setErrors(errorsFound[0])
                          // }
                        }}
                      >
                        ADD TO CART
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ResponsiveSpacing>
      <div className="garnishCont">
        <Garnish
          garnish={g[0]}
          pSpeed={-0.2}
          top={0}
          rotation={0}
          rSpeed={0.3}
          width={"150px"}
          pos="left"
        />
        <Garnish
          garnish={g[1]}
          pSpeed={-0.2}
          top={-130}
          width={200}
          rotation={0}
          rSpeed={0}
          pos="right"
        />
        <Banner inner={fields.short_info} color={theme.DarkBlue} />
      </div>
      <Heading margin={"150px 0 0 0"}>
        <h2
          dangerouslySetInnerHTML={{
            __html: fields.more_merch.headline,
          }}
        />
        <h3
          dangerouslySetInnerHTML={{
            __html: fields.more_merch.sub_heading,
          }}
        />
      </Heading>
      <MoreMerch merch={merch} dispatch={dispatch} />
      <BottomColorNav />
    </ContentStyled>
  )
}

const ConnectedContent = connect((state) => state)(Content)

export default (props) => {
  const merch = props.data.merch.edges.map((e) => e.node)
  const product = merch.filter((m) => m.id === props.pageContext.id)
  return (
    <Layout>
      <ConnectedContent
        merch={merch}
        product={product[0]}
        context={props.pageContext}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    merch: allWordpressWpMerch {
      edges {
        node {
          id
          title
          wordpress_id
          acf {
            top_line
            name
            name_with_html
            headline
            not_for_sale
            short_info
            sku
            price
            promotion
            images {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            option_choices {
              label
              choices
              default_choice
              input_type
            }
            option_skus {
              labels
              sku
              price
              sold_out
            }
            more_merch {
              headline
              sub_heading
            }
            search_title
            share_headline
            search_description
            share_comment
            share_image {
              source_url
            }
            garnish {
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 178, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
